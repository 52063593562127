import { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { KnockOutMatches } from "../features/matches/matchesSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { KNOCK_OUT_MATCH_TYPE, STATUS, Match, MATCH_TYPE } from "../types/types";
import moment, { Moment } from "moment";
import { dbUpdateGroupMatch2 } from "../api/api";
import { formatTime, matchExists } from "../utils";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import useConfirm from "../hooks/useConfirm";
import { DatePicker } from "@mui/x-date-pickers";
import NotificationService from "./notification/NotificationService";

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedKnockoutMatchKey?: keyof KnockOutMatches;
  knockOutMatchType: KNOCK_OUT_MATCH_TYPE;
  selectedMatch?: Match;
}

const CreateKnockoutMatchDialog = (props: Props) => {
  const { handleClose, open, selectedKnockoutMatchKey, selectedMatch } = props;
  const dispatch = useAppDispatch();
  const [homeTeamLabel, setHomeTeamLabel] = useState("");
  const [awayTeamLabel, setAwayTeamLabel] = useState("");
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");
  const teams = useAppSelector((state) => state.teams.teams);
  const tournament = useAppSelector((state) => state.tournament.tournament);

  const [startTime, setStartTime] = useState<Moment | null>(moment.unix(tournament.date));
  const [endTime, setEndTime] = useState<Moment | null>(moment.unix(tournament.date + 900));

  const locations = useAppSelector((state) => state.locations.locations);
  const [location, setLocation] = useState(locations[0]?.id);
  const selectedPulje = useAppSelector((state) => state.puljer.selectedPulje);
  const [ConfirmDialog, confirmDelete] = useConfirm("Er du sikker?", "Er du sikker på at du vil genstarte denne kamp?");

  useEffect(() => {
    if (!selectedMatch) return;
    selectedMatch.homeTeam && setHomeTeam(selectedMatch.homeTeam.id);
    selectedMatch.awayTeam && setAwayTeam(selectedMatch.awayTeam.id);
    selectedMatch.startTime && setStartTime(moment.unix(selectedMatch.startTime));
    selectedMatch.endTime && setEndTime(moment.unix(selectedMatch.endTime));
    selectedMatch.location && setLocation(selectedMatch.location.id);
    selectedMatch.homeTeamLabel && setHomeTeamLabel(selectedMatch.homeTeamLabel);
    selectedMatch.awayTeamLabel && setAwayTeamLabel(selectedMatch.awayTeamLabel);
  }, [selectedMatch]);

  const update = () => {
    if (!selectedKnockoutMatchKey || !selectedPulje?.id || !selectedMatch?.id) return;

    const body = {
      id: selectedMatch.id,
      homeTeam: homeTeam || null,
      awayTeam: awayTeam || null,
      status: STATUS.PENDING,
      startTime: startTime?.unix(),
      endTime: endTime?.unix(),
      location: location,
      homeTeamLabel,
      awayTeamLabel,
    };

    dbUpdateGroupMatch2(body.id, body).catch((e) => {
      NotificationService.showToast({
        severity: "error",
        text: "Kunne ikke opdatere knockout kamp",
        duration: 5000,
      });
      console.error("Kunne ikke opdatere knockout kamp!");
    });

    _handleClose();
  };

  const handleStartTimeChange = (_startTime?: number) => {
    if (!_startTime) return;
    setStartTime(moment.unix(_startTime));
    const _endTime = moment(_startTime, "HH:mm").add(10, "minutes");
    setEndTime(_endTime);
  };

  const _handleClose = () => {
    setHomeTeam("");
    setAwayTeam("");
    setStartTime(moment.unix(tournament.date));
    setEndTime(moment(tournament.date, "HH:mm").add(10, "minutes"));
    setLocation(locations[0].id);
    setHomeTeamLabel("");
    setAwayTeamLabel("");
    handleClose();
  };

  const reset = async () => {
    if (!selectedKnockoutMatchKey || !selectedPulje?.id || !selectedMatch?.id) return;

    const confirmed = await confirmDelete();
    if (confirmed) {
      dbUpdateGroupMatch2(selectedMatch.id, {
        id: selectedMatch.id,
        homeTeam: null,
        awayTeam: null,
        homeTeamPoints: 0,
        awayTeamPoints: 0,
        status: STATUS.PENDING,
        startTime: null,
        endTime: null,
        location: null,
        homeTeamLabel: null,
        awayTeamLabel: null,
        pulje: selectedPulje?.id,
        knockoutMatchId: selectedMatch.knockoutMatchId,
        tournament: tournament.id,
        matchType: MATCH_TYPE.KNOCKOUT_MATCH,
      }).finally(() => {
        _handleClose();
      });
    }
  };

  return (
    <>
      {ConfirmDialog()}
      <Dialog open={open} onClose={_handleClose}>
        <DialogContent sx={{ width: "500px", height: "380px" }}>
          <div style={{ display: "flex", justifyContent: "space-around", margin: "20px 0px", width: "100%" }}>
            <TextField
              type="text"
              sx={{ "& label": { color: "grey" }, width: "150px" }}
              label="Label"
              variant="standard"
              value={homeTeamLabel}
              onChange={(e: any) => setHomeTeamLabel(e.target.value)}
            />
            <TextField
              type="text"
              sx={{ "& label": { color: "grey" }, width: "150px" }}
              label="Label"
              variant="standard"
              value={awayTeamLabel}
              onChange={(e: any) => setAwayTeamLabel(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "center", margin: "20px 0px", width: "100%" }}>
            <FormControl variant="outlined" style={{ width: "200px" }}>
              <InputLabel id="demo-simple-select-label">Hjemmehold</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={homeTeam}
                label="Hometeam"
                onChange={(e) => setHomeTeam(e.target.value)}
              >
                {teams
                  .filter((obj) => obj.group?.pulje.id === selectedPulje?.id)
                  .sort((a, b) => a.teamName.localeCompare(b.teamName))
                  .map((element) => (
                    <MenuItem key={element.id} value={element.id}>
                      {element.teamName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ width: "200px" }}>
              <InputLabel>Udehold</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={awayTeam}
                label="Awayteam"
                onChange={(e) => setAwayTeam(e.target.value)}
              >
                {teams
                  .filter((obj) => obj.group?.pulje.id === selectedPulje?.id)
                  .sort((a, b) => a.teamName.localeCompare(b.teamName))
                  .map((element) => (
                    <MenuItem key={element.id} value={element.id}>
                      {element.teamName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around", margin: "20px 0px", width: "100%" }}>
            {/*      <DatePicker
              disableOpenPicker
              value={startTime}
              label="Start"
              onChange={(value) => handleStartTimeChange(value?.unix())}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="HH:mm"
            />
            <DatePicker
              disableOpenPicker
              value={endTime}
              label="Start"
              onChange={(value) => setEndTime(value)}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="HH:mm"
            /> */}
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-label">Bane</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={location}
                label="Location"
                onChange={(e) => setLocation(e.target.value)}
              >
                {locations.map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          {/*           <Button disabled={!matchExists(selectedMatch)} onClick={reset}>
            <RestartAltIcon />
          </Button> */}
          <Button onClick={_handleClose}>Annuller</Button>
          <Button variant="contained" onClick={update}>
            Opdater
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateKnockoutMatchDialog;
