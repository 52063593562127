import { useState, useEffect, useMemo } from "react";

export default function useWindowDimensions() {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  const isDesktop = useMemo(() => {
    return width >= 900;
  }, [width]);

  return { isDesktop };
}
