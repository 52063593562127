import { useState, useEffect, useMemo } from "react";
import MatchComponent from "../../components/match";
import MatchUpdateDialog from "../../components/match-update-dialog";
import { useAppSelector } from "../../hooks";
import { LocationType, Match, STATUS } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

interface Props {
  location?: LocationType;
  selectedLocation?: LocationType;
  isMatchesView: boolean;
}

function MatchList(props: Props) {
  const { location, isMatchesView, selectedLocation } = props;
  const matches = useAppSelector((state) => state.matches.groupMatches);
  const [isMatchUpdateDialogOpen, setIsMatchUpdateDialogOpen] = useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState<{ puljeId: string; matchId: string }>();
  const [selectedMatch, setSelectedMatch] = useState<Match>();
  const favoriteTeams = useAppSelector((state) => state.teams.favoriteTeams);
  const lastUpdatedMatch = useAppSelector((state) => state.matches.lastUpdatedMatch);
  const isAdmin = useAppSelector((state) => state.app.admin);
  const navigate = useNavigate();

  // MATCHES
  const filteredMatches = useMemo(() => {
    let returnObj = [...matches].sort((a, b) => a.startTime - b.startTime);

    if (isMatchesView) {
      return returnObj.filter((match: Match) => match.location.id === location?.id);
    } else {
      return returnObj.filter(
        (match: Match) => favoriteTeams?.includes(match.homeTeam?.id) || favoriteTeams?.includes(match.awayTeam?.id)
      );
    }
  }, [matches, location, favoriteTeams, isMatchesView]);

  useEffect(() => {
    if (selectedMatchId?.matchId && selectedMatchId.puljeId) {
      const foundMatch = filteredMatches?.find((match) => match.id === selectedMatchId.matchId);
      if (foundMatch) {
        setSelectedMatch(foundMatch);
        if (isAdmin) {
          setIsMatchUpdateDialogOpen(true);
        } else {
          navigate(ROUTES.MATCH_DETAILS + `/${foundMatch.id}`);
        }
      }
    } else {
      setSelectedMatch(undefined);
      setIsMatchUpdateDialogOpen(false);
    }
  }, [filteredMatches, selectedMatchId]);

  // Scroll to the first active 'Playing' match. If no matches are playing, go to next pending match.
  useEffect(() => {
    // timeout: Wait for the slider to be done with the animation before scrolling.
    setTimeout(() => {
      const activeMatch = document.getElementsByClassName(`${STATUS.PLAYING} ${selectedLocation?.id}`)[0];
      const nextPendingMatch = document.getElementsByClassName(`${STATUS.PENDING} ${selectedLocation?.id}`)[0];

      const scrollElement = activeMatch ?? nextPendingMatch;

      scrollElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }, 350);
  }, [lastUpdatedMatch, selectedLocation]);

  return (
    <>
      <MatchUpdateDialog
        match={selectedMatch}
        open={isMatchUpdateDialogOpen}
        handleClose={() => setSelectedMatchId(undefined)}
      />
      <div style={{ margin: "0 20px" }}>
        {filteredMatches && filteredMatches.length > 0 && (
          <>
            <div className="matches-list-header">Matches</div>
            {filteredMatches?.map((match: Match) => (
              <MatchComponent
                key={match.id}
                onClick={(puljeId, matchId) => setSelectedMatchId({ puljeId, matchId })}
                match={match}
                delay={location?.delay ?? 0}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default MatchList;
