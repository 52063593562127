import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import TextField from "@mui/material/TextField";
import { TeamType } from "../../../types/types";
import { dbCreateGroup, dbUpdateGroup } from "../../../api/api";
import NotificationService from "../../../components/notification/NotificationService";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { createGroup, setGroups } from "../../../features/groups/GroupsSlice";

interface Props {
  selectedTeam?: TeamType;
}

const CreateGroup = (props: Props) => {
  const editingGroup = useAppSelector((state) => state.groups.editingGroup);
  const selectedPulje = useAppSelector((state) => state.puljer.selectedPulje);
  const dispatch = useAppDispatch();
  const [groupName, setGroupName] = useState(editingGroup?.name ?? "");
  const groups = useAppSelector((state) => state.groups.groups);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));

  const createGroupHandler = () => {
    const groupExist = groups.filter((g) => g.pulje.id === selectedPulje?.id).some((group) => group.name === groupName);
    if (groupExist) {
      setErrorMessage("Group already exists");
      NotificationService.showToast({
        severity: "error",
        text: "Group already exists",
        duration: 3000,
      });
      return;
    }

    if (!selectedPulje) return;

    dbCreateGroup(groupName, selectedPulje.id)
      .then((id) => {
        dispatch(createGroup({ id, name: groupName, pulje: selectedPulje }));
        NotificationService.showToast({
          severity: "success",
          text: "Gruppen blev oprettet.",
          duration: 3000,
        });
        navigate(ROUTES.ADMIN_GROUPS);
      })
      .finally(() => {
        setGroupName("");
      });
  };

  const updateGroupHandler = () => {
    if (!editingGroup || !selectedPulje) return;

    dbUpdateGroup(selectedPulje.id, editingGroup.id, groupName)
      .then(() => {
        const updatedGroups = groups.map((group) => (group.id === editingGroup.id ? { ...group, name: groupName } : group));
        dispatch(setGroups(updatedGroups));
        NotificationService.showToast({
          severity: "success",
          text: "Gruppen blev opdateret.",
          duration: 3000,
        });
        navigate(ROUTES.ADMIN_GROUPS);
      })
      .catch((error) => {
        NotificationService.showToast({
          severity: "error",
          text: "Gruppen kunne ikke opdateres.",
          duration: 3000,
        });
        console.error(error);
      });
  };

  useEffect(() => {
    if (groupName.length > 12) {
      setErrorMessage("Max 12 characters");
    } else {
      setErrorMessage("");
    }
  }, [groupName]);

  return (
    <>
      <Container maxWidth={lgUp ? "sm" : "lg"}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="flex" spacing={4}>
            <Typography variant="h4">{editingGroup ? "Opdater gruppe" : "Opret gruppe"}</Typography>
          </Stack>
          <Card>
            <form autoComplete="off" noValidate onSubmit={() => {}}>
              <Card>
                <CardHeader subheader="Udfyld følgende oplysninger:" title={editingGroup ? "Opdater gruppe" : "Ny gruppe"} />
                <CardContent sx={{ pt: 0 }}>
                  <Box sx={{ m: -1.5 }}>
                    <Grid container>
                      <Grid xs={12} md={6} padding={1} item>
                        <TextField
                          fullWidth
                          label="Gruppe"
                          name="groupName"
                          onChange={(e) => setGroupName(e.target.value)}
                          required
                          value={groupName}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button variant="outlined" onClick={() => navigate(ROUTES.ADMIN_GROUPS)}>
                    Fortryd
                  </Button>
                  <Button
                    disabled={!!errorMessage || !groupName}
                    variant="contained"
                    onClick={editingGroup ? updateGroupHandler : createGroupHandler}
                  >
                    {editingGroup ? "Opdater" : "Opret"}
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Card>
        </Stack>
      </Container>
    </>
  );
};

export default CreateGroup;
