import { Match } from "./types/types";

export const updateTimes = (matches: Match[], initialTime: number, gapTime: number) => {
  const updatedList: Match[] = [];

  matches.forEach((match) => {
    if (updatedList.length === 0) {
      updatedList.push({
        ...match,
        startTime: initialTime,
        endTime: initialTime + match.duration * 60,
      });
    } else {
      const startTime = updatedList[updatedList.length - 1].endTime + gapTime * 60;
      updatedList.push({
        ...match,
        startTime,
        endTime: startTime + match.duration * 60,
      });
    }
  });

  return updatedList;
};
