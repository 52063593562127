import { Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

function NoFavorites() {
  return (
    <div className="error-page">
      <StarIcon sx={{ color: "#ff6b00", fontSize: 100, marginTop: "50px" }} />
      <Typography sx={{ textAlign: "center", marginTop: "20px", color: "white" }} variant="h3">
        No Favorites Yet
      </Typography>
      <Typography sx={{ textAlign: "center", marginTop: "30px", color: "white" }} variant="h6">
        Please select your favorite teams!
      </Typography>
    </div>
  );
}

export default NoFavorites;
