import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KNOCK_OUT_TOURNAMENT_SIZE, Pulje } from "../../types/types";

// Define a type for the slice state
interface PuljeState {
  selectedPulje?: Pulje;
  puljer: Array<Pulje>;
  knockoutTournamentSize?: KNOCK_OUT_TOURNAMENT_SIZE;
  editingPulje?: Pulje;
}

// Define the initial state using that type
const initialState: PuljeState = {
  selectedPulje: undefined,
  puljer: [],
  knockoutTournamentSize: undefined,
  editingPulje: undefined,
};

export const PuljeSlice = createSlice({
  name: "puljer",
  initialState,
  reducers: {
    setSelectedPulje: (state, action: PayloadAction<Pulje>) => {
      state.selectedPulje = action.payload;
    },
    setPuljer: (state, action: PayloadAction<Array<Pulje>>) => {
      state.puljer = action.payload;
    },
    setTournamentSize: (state, action: PayloadAction<KNOCK_OUT_TOURNAMENT_SIZE>) => {
      state.knockoutTournamentSize = action.payload;
    },
    createPulje: (state, action: PayloadAction<Pulje>) => {
      state.puljer.push(action.payload);
    },
    setEditingPulje: (state, action: PayloadAction<Pulje | undefined>) => {
      state.editingPulje = action.payload;
    },
    removePulje: (state, action: PayloadAction<string>) => {
      state.puljer = state.puljer.filter((pulje) => pulje.id !== action.payload);
    },
  },
});

export const { setSelectedPulje, setPuljer, setTournamentSize, setEditingPulje, createPulje, removePulje } =
  PuljeSlice.actions;

export default PuljeSlice.reducer;
