import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { calculateOverlap, formatTime, getTourrnamentTemplate, shuffle, updateTimes } from "../../../utils";
import { Match, MATCH_TYPE, STATUS } from "../../../types/types";
import NotificationService from "../../../components/notification/NotificationService";
import { dbUploadGroupMatches, dbMatchType } from "../../../api/api";
import { generateGroupMatches } from "../../../generateMatches";
import { setGroupMatches } from "../../../features/matches/matchesSlice";
import { duration } from "moment";

interface Props {}

const GenerateTournament = (props: Props) => {
  const dispatch = useAppDispatch();
  const tournament = useAppSelector((state) => state.tournament.tournament);
  const gapTime = useAppSelector((state) => state.settings.settings)?.gapTime ?? 0 / 60;
  const navigate = useNavigate();
  const locations = useAppSelector((state) => state.locations.locations);
  const matches = useAppSelector((state) => state.matches.groupMatches);
  const teams = useAppSelector((state) => state.teams.teams);
  const groups = useAppSelector((state) => state.groups.groups);
  const puljer = useAppSelector((state) => state.puljer.puljer);

  const createTournament = () => {
    if (gapTime <= 0) {
      NotificationService.showToast({
        severity: "error",
        text: "Match time and gap time must be positive numbers!",
        duration: 3000,
      });
      return;
    }

    // Generate group matches
    const matches = generateGroupMatches(puljer, groups, locations, tournament.date, gapTime).map(
      (match: dbMatchType & { id: string }) => {
        return {
          ...match,
          awayTeam: teams.find((team) => team.id === match.awayTeam),
          homeTeam: teams.find((team) => team.id === match.homeTeam),
          group: groups.find((group) => group.id === match.group),
          location: locations.find((location) => location.id === match.location),
          pulje: puljer.find((pulje) => pulje.id === (match.pulje as any).id),
          tournament: tournament,
        };
      }
    );

    let successList: Array<Match> = [];
    let tries;

    // Shuffle the groupmatches (max 1000 times) but keep the startTime, endTime and location.
    // If no overlaps are calculated, we successfully have a non-overlapping schedule
    for (let i = 0; i < 10000; i++) {
      let shuffledArray = shuffle(matches);
      shuffledArray = updateTimes(shuffledArray, tournament.date, gapTime * 60, locations);
      if (!calculateOverlap(shuffledArray)) {
        successList = shuffledArray;
        tries = i + 1;
        break;
      }
    }

    if (successList.length > 0) {
      console.log(`Success after ${tries} tries!`);
    } else {
      NotificationService.showToast({
        severity: "error",
        text: "Kunne ikke generere turnering uden overlap.",
        duration: 3000,
      });
      console.error("Could not generate tournament without overlaps.");
      return;
    }

    // Generate the knockout matches
    const knockoutMatches: Match[] = [];
    puljer.forEach((pulje) => {
      const matchesForPulje = getTourrnamentTemplate(pulje).map((match) => {
        return {
          ...match,
          duration: pulje.knockoutMatchTime,
          matchType: MATCH_TYPE.KNOCKOUT_MATCH,
          tournament: tournament,
        };
      });
      knockoutMatches.push(...matchesForPulje);
    });

    // Merge the group matches and the knockout matches
    successList.push(...knockoutMatches);

    // Update the times for all matches
    successList = updateTimes(successList, tournament.date, (gapTime ?? 0) * 60, locations);

    // Upload
    const readyForUpload = successList.map((match) => {
      return {
        id: match.id,
        homeTeam: match.homeTeam?.id,
        awayTeam: match.awayTeam?.id,
        homeTeamPoints: 0,
        awayTeamPoints: 0,
        startTime: match.startTime,
        endTime: match.endTime,
        duration: match.duration,
        matchType: match.matchType,
        status: STATUS.PENDING,
        location: match.location?.id,
        group: match.group?.id,
        pulje: match.pulje?.id,
        knockoutMatchId: match.knockoutMatchId,
        tournament: tournament.id,
      } as dbMatchType;
    });

    if (readyForUpload) {
      dbUploadGroupMatches(readyForUpload)
        .then(() => {
          dispatch(setGroupMatches(successList));
          navigate(ROUTES.ADMIN_MATCHES);
        })
        .catch((error) => {
          console.error(error);
          NotificationService.showToast({
            severity: "error",
            text: "Kunne ikke upload turnering til databasen.",
            duration: 3000,
          });
        });
    } else {
      NotificationService.showToast({
        severity: "error",
        text: "Kunne ikke generere turnering.",
        duration: 3000,
      });
      console.error("successObj not was falsy.");
    }
  };

  return (
    <Container maxWidth="xl">
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="h4">Generer turnering</Typography>
        </Stack>
        <Card>
          <form autoComplete="off" noValidate onSubmit={() => {}}>
            <Card>
              <CardHeader subheader="Udfyld følgende oplysninger:" title="Generer turnering" />
              <CardContent sx={{ pt: 0 }}>
                <Box sx={{ m: -1.5 }}>
                  <Grid container>
                    <Grid xs={12} padding={1} item>
                      <TextField
                        fullWidth
                        label="Start tidspunkt"
                        name="start-time"
                        value={formatTime(tournament.date)}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
              <Divider />
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button variant="outlined" onClick={() => navigate(ROUTES.ADMIN_MATCHES)}>
                  Fortryd
                </Button>
                <Button disabled={matches.length > 0} variant="contained" onClick={createTournament}>
                  Opret
                </Button>
              </CardActions>
            </Card>
          </form>
        </Card>
      </Stack>
    </Container>
  );
};

export default GenerateTournament;
