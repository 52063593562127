import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useAppSelector } from "../../../hooks";
import {
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import PuljeSelectorTabs from "../../../components/pulje-selector-tabs";
import {
  getDraws,
  getGoalDifference,
  getGoalsAway,
  getGoalsFor,
  getLoses,
  getPlays,
  getPoints,
  getWins,
  sortTeams,
} from "../../../utils";
import { MATCH_TYPE, TeamType } from "../../../types/types";
import ballLogo from "../../../assets/icons/ball-logo.png";
import NotificationService from "../../../components/notification/NotificationService";

function getStyles(teamId: string, teamIds: string[], theme: Theme) {
  return {
    fontWeight: teamIds.includes(teamId) ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
  };
}

interface ExtendedTeam extends TeamType {
  points: number;
  goalsFor: number;
  goalDifference: 3;
}

export default function Seeding() {
  const _matches = useAppSelector((state) => state.matches.groupMatches);
  const selectedPulje = useAppSelector((state) => state.puljer.selectedPulje);
  const _groups = useAppSelector((state) => state.groups.groups).filter(
    (group) => (group?.pulje as any).id === selectedPulje?.id)

  const [groupsToFilter, setGroupsToFilter] = useState<string[]>([]);
  const [teamsToFilter, setTeamsToFilter] = useState<string[]>([]);
  const [evenTeams, setEvenTeams] = useState<string[]>([]);
  const [showError, setShowError] = useState(false);

  const theme = useTheme();

  const handleTeamFilterChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setTeamsToFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleGroupFilterChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setGroupsToFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const groups = useMemo(() => {
    return _groups.filter(group => !groupsToFilter.includes(group.id));
  }, [selectedPulje, _groups, groupsToFilter])


  const teams = groups.map((group) => group.teams).flat();


  // FILTER ON GROUP-MATCHES AND REMOVE TEAMS-TO-FILTER
  const filteredMatches = useMemo(() => {
    const groupMatches = _matches.filter((match) => match.matchType === MATCH_TYPE.GROUP_MATCH);

    if (teamsToFilter.length > 0) {
      const result = groupMatches.filter((match) => !teamsToFilter.includes(match.homeTeam.id)).filter((match) => !teamsToFilter.includes(match.awayTeam.id));
      return result
    }
    return groupMatches;
  }, [_matches, teamsToFilter]);

  // REMOVE FILTERED TEAM FROM GROUP AND SORT
  const sortedAndFilteredGroups = useMemo(() => {

    const filteredGroups = groups.map((group) => {
      if (group.teams?.some((team) => teamsToFilter.includes(team.id))) {
        return {
          ...group,
          teams: group.teams.filter((team) => !teamsToFilter.includes(team.id)),
        };
      } else {
        return group;
      }
    });
    let groupsToSort = teamsToFilter.length > 0 ? filteredGroups : groups;

    //SORTER GRUPPEVIS (A, B, C, D)
    const filteredAndSortedGroup = groupsToSort.map((group) => group.teams && sortTeams(group.teams, filteredMatches, true));

    const lengths = new Set([...filteredAndSortedGroup.map((group) => group?.length ?? 0)]);

    if (lengths.size !== 1) {
      setShowError(true);
      console.log("Der er ikke lige mange hold pr gruppe");
      return [];
    }

    setShowError(false)

    const size = lengths.values().next().value ?? 0;

    const result = [];

    //SORTER PLADS VIS (1. pladserne, 2. pladserne.. osv)
    for (let index = 0; index < size; index++) {
      const s = sortTeams(
        filteredAndSortedGroup.map((group: any) => group[index]),
        filteredMatches,
        false
      )
      result.push(s);
    }
    return result;
  }, [filteredMatches, selectedPulje, teamsToFilter, groupsToFilter]);

  //MARK EVEN TEAMS
  useEffect(() => {
    sortedAndFilteredGroups.forEach((_teams: any) => {
      _teams.find((t1: ExtendedTeam) => {
        const team2 = _teams.find(
          (t2: ExtendedTeam) =>
            t1.id !== t2.id &&
            t2.points === t1.points &&
            t2.goalDifference === t1.goalDifference &&
            t2.goalsFor === t1.goalsFor
        );
        if (team2 && t1) {
          setEvenTeams((prev) => [...prev, t1.id]);
          setEvenTeams((prev) => [...prev, team2.id]);
        }
        return team2;
      });
    });
  }, [sortedAndFilteredGroups]);

  return (
    <Container maxWidth="xl">
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="h4">Seeding</Typography>
        </Stack>
        <PuljeSelectorTabs />
        <Stack direction='row' >

          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">Hold filtering</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={teamsToFilter}
              onChange={handleTeamFilterChange}
              input={<OutlinedInput label="Name" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
            >
              {teams.sort((a, b) => (a?.teamName ?? '').localeCompare((b?.teamName ?? ''))).map((team) => (
                <MenuItem
                  key={team?.id}
                  value={team?.id}
                  style={getStyles(
                    team?.id ?? "",
                    teams.map((team) => team?.id ?? ""),
                    theme
                  )}
                >
                  {team?.teamName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">Gruppe filtering</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={groupsToFilter}
              onChange={handleGroupFilterChange}
              input={<OutlinedInput label="Name" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
            >
              {_groups.map((group) => (
                <MenuItem
                  key={group?.id}
                  value={group?.id}
                  style={getStyles(
                    group?.id ?? "",
                    _groups.map((group) => group?.id ?? ""),
                    theme
                  )}
                >
                  {group?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Box sx={{ minWidth: 300 }}>
          {
            showError ?
              <Typography sx={{ color: 'grey', width: '100%', textAlign: 'center' }} variant="inherit" >Der er ikke lige mange hold pr. gruppe</Typography>
              :

              <Card>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Hold</TableCell>
                      <TableCell>F</TableCell>
                      <TableCell>GD</TableCell>
                      <TableCell>PTS</TableCell>
                    </TableRow>
                  </TableHead>
                  {

                    <TableBody>
                      {sortedAndFilteredGroups.flat().map((team, place) => {
                        return (
                          <TableRow hover key={team.id}>
                            <TableCell>{(place + 1)}</TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: evenTeams.includes(team.id) ? "red" : "black",
                              }}
                            >
                              <img src={team.club?.logo ?? ballLogo} height={20} style={{ marginRight: "10px" }} />
                              {team.teamName}
                            </TableCell>
                            <TableCell>{getGoalsFor(team.id, filteredMatches)}</TableCell>
                            <TableCell>{getGoalDifference(team.id, filteredMatches)}</TableCell>
                            <TableCell>{getPoints(team.id, filteredMatches)}</TableCell>
                          </TableRow>
                        )
                      }
                      )}
                    </TableBody>
                  }
                </Table>
              </Card>
          }
        </Box>
      </Stack>
    </Container>
  );
}
