import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { TeamType } from "../../../types/types";
import Button from "@mui/material/Button";
import { dbDeleteTeam } from "../../../api/api";
import { removeTeam, setEditingTeam } from "../../../features/teams/TeamsSlice";
import { removeTeamFromGroup } from "../../../features/groups/GroupsSlice";
import NotificationService from "../../../components/notification/NotificationService";
import useConfirm from "../../../hooks/useConfirm";
import { Card, Container, Stack, SvgIcon, Typography } from "@mui/material";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import PuljeSelectorTabs from "../../../components/pulje-selector-tabs";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { TableCellIconStyles } from "../../../styles/theme";
import ballLogo from "../../../assets/icons/ball-logo.png";

export default function Teams() {
  const _teams = useAppSelector((state) => state.teams.teams);
  const dispatch = useAppDispatch();
  const groupMatches = useAppSelector((state) => state.matches.groupMatches);
  const [Dialog, confirmDelete] = useConfirm("Are you sure?", "Are you sure you want to delete this team?");
  const selectedPulje = useAppSelector((state) => state.puljer.selectedPulje);
  const teams = _teams
    .filter((team) => (team.group?.pulje as any).id === selectedPulje?.id)
    .sort((a, b) => a.teamName.localeCompare(b.teamName));

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setEditingTeam(undefined));
  }, []);

  const calculateMatchesUsingTeam = (teamId: string) => {
    return groupMatches.filter((match) => match.homeTeam?.id === teamId || match.awayTeam?.id === teamId).length;
  };

  const onDeleteTeamHandler = async (teamId: string, groupId?: string) => {
    if (!groupId) return;
    // Check if any matches are using this team!
    const matchesUsingTeam = calculateMatchesUsingTeam(teamId);
    if (matchesUsingTeam > 0) {
      NotificationService.showToast({
        severity: "error",
        text: `Dette hold spiller allerede ${matchesUsingTeam} kampe.`,
        duration: 3000,
      });
      return;
    }

    const confirmed = await confirmDelete();
    if (confirmed) {
      dbDeleteTeam(teamId)
        .then(() => {
          dispatch(removeTeam(teamId));
          dispatch(removeTeamFromGroup({ teamId, groupId }));

          NotificationService.showToast({
            severity: "success",
            text: `Holdet blev oprettet`,
            duration: 3000,
          });
        })
        .catch((e) => {
          console.error(e);
          NotificationService.showToast({
            severity: "error",
            text: `Dette hold kunne ikke slettes.`,
            duration: 3000,
          });
        });
    }
  };

  const editTeamHandler = (team: TeamType) => {
    dispatch(setEditingTeam(team));
    navigate(ROUTES.ADMIN_CREATE_TEAM);
  };

  return (
    <>
      {Dialog()}
      <Container maxWidth="xl">
        <Stack spacing={3} marginBottom={"50px"}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="h4">Hold</Typography>
            <div>
              <Button
                onClick={() => navigate(ROUTES.ADMIN_CREATE_TEAM)}
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                Opret
              </Button>
            </div>
          </Stack>
          <PuljeSelectorTabs />
          <Box sx={{ minWidth: 300 }}>
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{`Hold (${teams.length})`}</TableCell>
                    <TableCell>Gruppe</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teams.map((team, i) => {
                    return (
                      <TableRow hover key={team.teamName + team.group?.id}>
                        <TableCell>
                          <Stack alignItems="center" direction="row" spacing={2}>
                            <img height={22} src={team.club?.logo ?? ballLogo} />
                            <Typography variant="subtitle2">{team.teamName}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>{team.group?.name}</TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "40px" }}
                        >
                          <ModeEditOutlineOutlinedIcon sx={TableCellIconStyles} onClick={() => editTeamHandler(team)} />
                          <DeleteOutlineOutlinedIcon
                            sx={TableCellIconStyles}
                            onClick={() => onDeleteTeamHandler(team.id, team.group?.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Card>
          </Box>
        </Stack>
      </Container>
    </>
  );
}
