function RoundOf16Lines() {
  return (
    <div className="lines-container">
      <div className="line-container">
        <div className="arrow"></div>
        <div className="box" style={{ width: "250px" }}></div>
      </div>
      <div className="line-container">
        <div className="arrow"></div>
        <div className="box" style={{ width: "250px" }}></div>
      </div>
      <div className="line-container">
        <div className="arrow"></div>
        <div className="box" style={{ width: "250px" }}></div>
      </div>
      <div className="line-container">
        <div className="arrow"></div>
        <div className="box" style={{ width: "250px" }}></div>
      </div>
    </div>
  );
}

export default RoundOf16Lines;
