import { useMemo } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Fragment, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { ListItemIcon, SvgIconClasses, SxProps, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { addFavoriteTeam, removeFavoriteTeam } from "../features/teams/TeamsSlice";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ballLogo from "../assets/icons/ball-logo.png";
import { TeamType } from "../types/types";

interface Props {}

const drawerStyles: SxProps = {
  backgroundColor: "rgba(0, 0, 0, 0.75)", //Backdrop color

  ".MuiDrawer-paper": {
    backgroundColor: "rgba(0, 0, 0, 0)", //drawe menu background color
    boxShadow: "none",
    margin: "30px 20px",
    color: "white",
  },
};

const FavoritesDrawer = (props: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const allTeams = useAppSelector((state) => state.teams.teams);
  const favoriteTeams = useAppSelector((state) => state.teams.favoriteTeams);
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state) => state.groups.groups);
  const _puljer = useAppSelector((state) => state.puljer.puljer);
  const tournamentDate = useAppSelector((state) => state.tournament.tournament.date);

  const favoriteTeamSelectedHandler = (e: any, teamId: string) => {
    const date = new Date(tournamentDate * 1000);
    e?.stopPropagation();
    if (favoriteTeams.some((team) => team === teamId)) {
      dispatch(removeFavoriteTeam({ teamId: teamId, tournamentDate: date }));
    } else {
      dispatch(addFavoriteTeam({ teamId: teamId, tournamentDate: date }));
    }
  };

  const puljer = useMemo(() => {
    const map1 = new Map();

    allTeams.forEach((team) => {
      const pulje = groups.find((group) => group.id === team.group?.id)?.pulje;
      if (map1.get(pulje)) {
        map1.set(pulje, [...map1.get(pulje).values(), team]);
      } else {
        map1.set(pulje, [team]);
      }
    });

    return Array.from(map1, ([pulje, teams]) => ({ pulje, teams })).map((pulje) => {
      return {
        ...pulje,
        teams: pulje.teams.sort((a: TeamType, b: TeamType) => a.teamName.localeCompare(b.teamName)),
      };
    });
  }, [allTeams]);

  const addAllTeamsFromPulje = (pulje: any) => {
    const date = new Date(tournamentDate * 1000);

    if (pulje.teams.some((team: TeamType) => favoriteTeams.includes(team.id))) {
      pulje.teams.forEach((team: TeamType) => {
        dispatch(removeFavoriteTeam({ teamId: team.id, tournamentDate: date }));
      });
    } else {
      pulje.teams.forEach((team: TeamType) => {
        favoriteTeamSelectedHandler(null, team.id);
      });
    }
  };

  return (
    <Fragment>
      {favoriteTeams.length > 0 ? (
        <StarIcon
          fontSize={"large"}
          onClick={() => setIsDrawerOpen(true)}
          sx={{ ":hover": { cursor: "pointer" }, color: "white" }}
        />
      ) : (
        <StarBorderIcon
          fontSize={"large"}
          onClick={() => setIsDrawerOpen(true)}
          sx={{ ":hover": { cursor: "pointer" }, color: "white" }}
        />
      )}
      <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} sx={drawerStyles}>
        <Box paddingBottom={5} role="presentation" onClick={() => setIsDrawerOpen(true)}>
          {puljer.map((pulje, index) => (
            <List key={pulje.pulje}>
              <Typography
                sx={{ textAlign: "left", marginBottom: "10px" }}
                variant="h6"
                onClick={() => addAllTeamsFromPulje(pulje)}
              >
                {_puljer.find((_pulje) => _pulje.id === pulje.pulje.id)?.puljeName ?? ""}
              </Typography>
              <div style={{ paddingBottom: "10px" }}>
                {pulje.teams.map((team: TeamType) => (
                  <ListItem key={team.id} disablePadding>
                    <ListItemButton onClick={(e) => favoriteTeamSelectedHandler(e, team.id)}>
                      <ListItemIcon sx={{ minWidth: "35px" }}>
                        <img src={team.club?.logo ? team.club?.logo : ballLogo} height={20} />
                      </ListItemIcon>
                      <ListItemText
                        sx={
                          favoriteTeams.includes(team.id)
                            ? {
                                color: "#ffffff",
                                "& span": { fontWeight: 600 },
                                textDecoration: "underline",
                                textUnderlineOffset: "6px",
                              }
                            : {}
                        }
                        primary={team.teamName}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </div>
            </List>
          ))}
        </Box>
      </Drawer>
    </Fragment>
  );
};

export default FavoritesDrawer;
