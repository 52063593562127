import { addToast, removeToast } from "../../features/notification/notificationSlice";
import { store } from "../../store";
import { ToastModel } from "./Toast";

const showToast = (toast: ToastModel) => {
  store.dispatch(addToast({ toast }));
};

const clearToast = () => {
  store.dispatch(removeToast());
};

const NotificationService = {
  showToast,
  clearToast,
};

export default NotificationService;
