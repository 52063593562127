import { Match, STATUS } from "../types/types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { dbUpdateGroupMatch } from "../api/api";
import { useAppSelector } from "../hooks";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useState } from "react";
import AlertDialog from "./alert";
import { buttonStyles, dialogActionsStyles, dialogContentStyles, dialogStyles } from "../styles/theme";
import MatchData from "./match-data";

interface Props {
  open: boolean;
  handleClose: () => void;
  match?: Match;
}

function MatchUpdateDialog(props: Props) {
  const { open, handleClose, match } = props;
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const isAdmin = useAppSelector((state) => state.app.admin);

  const updateMatchStatus = (newStatus: STATUS) => {
    if (!match?.id) return;

    dbUpdateGroupMatch(match.id, "status", newStatus);
    if (newStatus === STATUS.FINISHED) handleClose();
  };

  const resetMatch = () => {
    if (!match?.id) return;

    dbUpdateGroupMatch(match.id, "homeTeamPoints", 0);
    dbUpdateGroupMatch(match.id, "awayTeamPoints", 0);
    updateMatchStatus(STATUS.PENDING);
    setOpenAlertDialog(false);
  };

  return (
    <div>
      <AlertDialog
        header="Er du sikker?"
        body="Er du sikker på at du vil nulstille denne kamp? Kamp-resultatet vil gå tabt."
        open={openAlertDialog}
        yesButtonText="Bekræft"
        yesCallback={resetMatch}
        noButtonText="Annuller"
        noCallback={() => setOpenAlertDialog(false)}
      />
      <Dialog open={open && !!isAdmin && !!match} onClose={handleClose} sx={dialogStyles}>
        <DialogContent sx={{ ...dialogContentStyles }}>
          {match && (
            <>
              <MatchData match={match} />
            </>
          )}
        </DialogContent>

        <DialogActions sx={dialogActionsStyles}>
          {match?.status !== STATUS.FINISHED && (
            <>
              <Button
                sx={buttonStyles}
                onClick={() => updateMatchStatus(STATUS.PLAYING)}
                disabled={match?.status === STATUS.PLAYING || !match?.homeTeam || !match?.awayTeam}
              >
                Start kamp
              </Button>
              <Button
                sx={buttonStyles}
                onClick={() => updateMatchStatus(STATUS.FINISHED)}
                disabled={match?.status !== STATUS.PLAYING}
              >
                Afslut kamp
              </Button>
            </>
          )}
          {match?.status === STATUS.FINISHED && (
            <Button sx={buttonStyles} onClick={() => setOpenAlertDialog(true)}>
              <RestartAltIcon />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MatchUpdateDialog;
