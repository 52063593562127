import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { buttonStyles, dialogActionsStyles, dialogContentStyles, dialogStyles } from "../styles/theme";
import { Typography } from "@mui/material";

interface Props {
  open: boolean;
  header: string;
  body: string;
  yesButtonText: string;
  yesCallback: () => void;
  noButtonText: string;
  noCallback: () => void;
}

export default function AlertDialog(props: Props) {
  const { open, header, body, yesButtonText, yesCallback, noButtonText, noCallback } = props;

  return (
    <Dialog open={open} sx={dialogStyles} onClose={noCallback}>
      <DialogTitle sx={dialogContentStyles}>
        <Typography color={"lightgray"} variant="h5">
          {header}
        </Typography>
      </DialogTitle>
      <DialogContent sx={dialogContentStyles}>
        <Typography color={"lightgray"} variant="subtitle1">
          {body}
        </Typography>
      </DialogContent>
      <DialogActions sx={dialogActionsStyles}>
        <Button sx={buttonStyles} onClick={yesCallback}>
          {yesButtonText}
        </Button>
        <Button sx={buttonStyles} onClick={noCallback} autoFocus>
          {noButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
