import { createTheme, SxProps } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createMixins";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#3A3A3A",
    },
    secondary: {
      main: "#CDCDCD",
    },
    info: {
      main: "#ffffff",
    },
    text: {
      disabled: "#3A3A3A",
    },
    action: {
      disabledBackground: "#3A3A3A",
      disabled: "#CDCDCD4D",
      disabledOpacity: 10,
    },
  },
  typography: {
    h1: { color: "#ff6b00", fontWeight: "bold", fontSize: "40px" },
    h2: { color: "#ff6b00", fontWeight: "bold", fontSize: "36px" },
    h3: { color: "#ff6b00", fontWeight: "bold", fontSize: "30px" },
    h4: { color: "#ff6b00", fontWeight: "bold", fontSize: "26px" },
    h5: { color: "#ff6b00", fontWeight: "bold", fontSize: "18px" },
    h6: { color: "#ff6b00b8", fontWeight: "bold", fontSize: "12px" },
    subtitle1: { color: "white", fontWeight: "lighter", fontSize: "12px" },
  },
});

export const tabsStyles: SxProps = {
  ".MuiTab-root.Mui-selected": {
    color: "#ff6b00",
  },
  ".MuiTab-root": {
    color: "#AAAAAA",
  },
  "span.MuiTabs-indicator": {
    backgroundColor: "#ff6b00 ",
  },
};

export const tableCellStyles: SxProps = {
  padding: "10px",
  height: "40px",
};

export const tableRowStyles: SxProps = {};

export const paperStyles: SxProps = {
  backgroundColor: "transparent",
  boxShadow: "unset",
};

export const buttonStyles: SxProps = {
  color: "white",
  ":disabled": {
    color: "grey",
    fontStyle: "italic",
  },
};

export const dialogStyles: SxProps = {
  "& .MuiPaper-root": {
    borderRadius: "10px",
    backgroundColor: "#141414",
  },
};

export const dialogContentStyles: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  backgroundColor: "#141414",
  fontSize: "18px",
};

export const dialogActionsStyles: SxProps = {
  backgroundColor: "#141414",
  padding: "20px 24px",
  borderTop: "1px solid #3c3c3c",
  height: "15px",
};

export const textFieldInputLabelProps: CSSProperties = {
  color: "#b9b9ba",
  borderRadius: "16px",
};

export const textFieldStyles: SxProps = {
  marginBottom: "20px",

  "& .MuiFilledInput-root": {
    backgroundColor: "transparent",
  },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: "transparent",
  },
  input: {
    textAlign: "left",
    color: "white",
    fontSize: 15,
    letterSpacing: "2px",
  },
  fieldset: {
    borderRadius: "16px",
    border: "1px solid #3c3c3c",
  },
};

export const formControlStyles: CSSProperties = {
  width: "130px",
  borderRadius: "16px",
  margin: "0px 5px",
  border: "1px solid #3c3c3c",
  height: "55px",
};

export const selectStyles: CSSProperties = {
  color: "white",
  borderRadius: "16px",
};

export const TableCellIconStyles: CSSProperties = {
  paddingLeft: "15px",
  color: "rgb(108, 115, 127)",
  ":hover": { cursor: "pointer" },
};
