import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GroupType, TeamType } from "../../types/types";

// Define a type for the slice state
interface GroupState {
  groups: Array<GroupType>;
  editingGroup?: GroupType;
}

interface RemoveTeamFromGroup {
  teamId: string;
  groupId: string;
}

// Define the initial state using that type
const initialState: GroupState = {
  groups: [],
  editingGroup: undefined,
};

export const GroupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<Array<GroupType>>) => {
      state.groups = action.payload.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    createGroup: (state, action: PayloadAction<GroupType>) => {
      state.groups = [...state.groups, action.payload].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    removeGroup: (state, action: PayloadAction<string>) => {
      state.groups = state.groups.filter((group) => group.id !== action.payload);
    },
    updateGroup: (state, action: PayloadAction<{ groupId: string; groupName: string }>) => {
      state.groups = state.groups.map((group) => {
        if (group.id === action.payload.groupId) {
          return {
            ...group,
            name: action.payload.groupName,
          };
        } else {
          return group;
        }
      });
    },
    addTeamToGroup: (state, action: PayloadAction<{ team: TeamType; groupId: string }>) => {
      state.groups = state.groups.map((group) => {
        if (group.id === action.payload.groupId) {
          if (group.teams) {
            return {
              ...group,
              teams: [...group.teams, action.payload.team],
            };
          } else {
            return {
              ...group,
              teams: [action.payload.team],
            };
          }
        } else {
          return group;
        }
      });
    },
    removeTeamFromGroup: (state, action: PayloadAction<RemoveTeamFromGroup>) => {
      state.groups = state.groups.map((group) => {
        if (group.id === action.payload.groupId && group.teams) {
          return {
            ...group,
            teams: [...group.teams.filter((team) => team.id !== action.payload.teamId)],
          };
        } else {
          return group;
        }
      });
    },
    setEditingGroup: (state, action: PayloadAction<GroupType | undefined>) => {
      state.editingGroup = action.payload;
    },
  },
});

export const { setGroups, createGroup, addTeamToGroup, removeGroup, removeTeamFromGroup, updateGroup, setEditingGroup } =
  GroupsSlice.actions;

export default GroupsSlice.reducer;
