import { KNOCK_OUT_MATCH_TYPE, Match, STATUS, TeamType } from "../types/types";
import { Card, CardActionArea, CardContent } from "@mui/material";
import ballLogo from "../assets/icons/ball-logo.png";
import { formatTime2, getKnockoutRoundType, getLogo } from "../utils";
import { ROUTES } from "../constants";
import { useLocation } from "react-router-dom";
import { KnockOutMatches } from "../features/matches/matchesSlice";

interface Props {
  onClick?: (matchId: string, puljeId: string) => void;
  match: Match;
  delay: number;
}

function MatchComponent(props: Props) {
  const { match, onClick, delay } = props;
  const pathname = useLocation().pathname;
  const isMatchesView = pathname === ROUTES.ROOT || pathname === ROUTES.MATCHES;

  const getBackgroundColor = () => {
    switch (getKnockoutRoundType(match.knockoutMatchId as keyof KnockOutMatches)) {
      case KNOCK_OUT_MATCH_TYPE.ROUND_OF_16:
        return "red";
      case KNOCK_OUT_MATCH_TYPE.QUARTER_FINAL:
        return "#c34c08";
      case KNOCK_OUT_MATCH_TYPE.SEMI_FINAL:
        return "#ff6b00";
      case KNOCK_OUT_MATCH_TYPE.THIRD:
        return "#ff8400";
      case KNOCK_OUT_MATCH_TYPE.FINAL:
        return "#ff9c1a";
    }
  };

  return (
    <>
      {match.knockoutMatchId && (
        <div
          className="knock-out-label"
          style={{
            backgroundColor: getBackgroundColor(),
            opacity: match.status === STATUS.FINISHED || match.status === STATUS.CANCELLED ? 0.3 : 1,
          }}
        >
          {getKnockoutRoundType(match.knockoutMatchId as keyof KnockOutMatches)}
        </div>
      )}
      <Card
        onClick={() => onClick && onClick(match.pulje.id, match.id)}
        className={`${match.status} ${match.location.id}`}
        sx={{
          padding: "5px 0px",
          backgroundColor: "transparent",
          boxShadow: "unset",
          opacity: match.status === STATUS.FINISHED || match.status === STATUS.CANCELLED ? 0.3 : 1,
          fontStyle: match.status === STATUS.FINISHED || match.status === STATUS.CANCELLED ? "italic" : "italic",
        }}
      >
        <CardActionArea>
          <CardContent
            sx={{
              fontStyle: match.status === STATUS.FINISHED || match.status === STATUS.CANCELLED ? "italic" : "normal",
              padding: 0,
            }}
          >
            <div className="match" style={{ padding: match.knockoutMatchId ? "18px 0px 10px 0px" : "0px" }}>
              <div className={match.status === STATUS.PLAYING ? "column-0" : ""}></div>
              <div className="column-1">
                {match.status === STATUS.FINISHED ? "FT" : formatTime2(match.startTime + match.location.delay * 60)}
              </div>
              <div className="column-2">
                <div className="team">
                  {getLogo(match.homeTeam)}

                  {match.homeTeam ? (
                    <span>{match.homeTeam.teamName}</span>
                  ) : (
                    <span style={{ fontStyle: "italic" }}>{match.homeTeamLabel ? match.homeTeamLabel : "Pending"}</span>
                  )}
                </div>
                <div className="team">
                  {getLogo(match.awayTeam)}

                  {match.awayTeam ? (
                    <span>{match.awayTeam?.teamName}</span>
                  ) : (
                    <span style={{ fontStyle: "italic" }}>{match.awayTeamLabel ? match.awayTeamLabel : "Pending"}</span>
                  )}
                </div>
              </div>
              <div className="column-3">
                {isMatchesView ? (
                  match.pulje.puljeName
                ) : (
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ margin: "2px" }}>{match.location.location}</div>
                    <div style={{ margin: "2px" }}>{match.pulje.puljeName}</div>
                  </div>
                )}
              </div>
              <div className="column-4">
                <div className="point">
                  {match.status !== STATUS.PENDING && match.status !== STATUS.CANCELLED && match.homeTeamPoints}
                </div>
                <div className="point">
                  {match.status !== STATUS.PENDING && match.status !== STATUS.CANCELLED && match.awayTeamPoints}
                </div>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
}

export default MatchComponent;
