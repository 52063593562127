import { useState } from "react";
import CreateKnckoutMatchDialog from "../../../components/update-knockout-match-dialog";
import KnockOutMatch from "../../../components/knockout-match";
import { KnockOutMatches } from "../../../features/matches/matchesSlice";
import { useAppSelector } from "../../../hooks";
import { KNOCK_OUT_MATCH_TYPE, Match } from "../../../types/types";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function FinalTeams() {
  const pulje = useAppSelector((state) => state.puljer.selectedPulje)?.id;
  const [selectedKnockoutMatch, setSelectedKnockoutMatch] = useState<keyof KnockOutMatches>();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<Match>();
  const matches = useAppSelector((state) => state.matches.groupMatches);
  const f = matches.find((match) => match.pulje.id === pulje && match.knockoutMatchId === "f");

  const { isDesktop } = useWindowDimensions();

  const knockOutMatchClickedHandler = (match: Match) => {
    setSelectedMatch(match);
    setSelectedKnockoutMatch(match.knockoutMatchId as any);
    setOpenDialog(true);
  };

  const closeDialogHandler = () => {
    setSelectedKnockoutMatch(undefined);
    setSelectedMatch(undefined);
    setOpenDialog(false);
  };

  return (
    <>
      <CreateKnckoutMatchDialog
        open={openDialog}
        handleClose={closeDialogHandler}
        selectedMatch={selectedMatch}
        selectedKnockoutMatchKey={selectedKnockoutMatch}
        knockOutMatchType={KNOCK_OUT_MATCH_TYPE.FINAL}
      />
      <div className="knockout-teams">
        <KnockOutMatch onClickCallback={(match) => isDesktop && knockOutMatchClickedHandler(match)} match={f} />
      </div>
    </>
  );
}

export default FinalTeams;
