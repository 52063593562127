import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Container, Stack, Typography } from "@mui/material";
import PuljeSelectorTabs from "../../../components/pulje-selector-tabs";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import ThirdTeams from "../../knockout/third/third-teams";
import FinalTeams from "../../knockout/final/final-teams";
import SemiFinalsLines from "../../knockout/semi-finals/semi-finals-lines";
import SemiFinalsTeams from "../../knockout/semi-finals/semi-finals-teams";
import { KNOCK_OUT_TOURNAMENT_SIZE } from "../../../types/types";
import QuarterFinalsTeams from "../../knockout/quarter-finals/quarter-finals-teams";
import QuarterFinalsLeftLines from "../../knockout/quarter-finals/quarter-finals-lines";
import RoundOf16Lines from "../../knockout/round-of-16/round-of-16-lines";
import RoundOf16Teams from "../../knockout/round-of-16/round-of-16-teams";

const KnockOut = () => {
  const knockoutTournamentSize = useAppSelector((state) => state.puljer.selectedPulje)?.puljeSize;
  const { isDesktop } = useWindowDimensions();
  const matches = useAppSelector((state) => state.matches);

  return (
    <>
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="h4">Knockout</Typography>
          </Stack>
          <PuljeSelectorTabs />

          {matches.groupMatches.length > 0 && (
            <Box sx={{ minWidth: 300 }}>
              <div className="knockout-wrapper">
                <TransformWrapper
                  initialScale={isDesktop ? 0.7 : 0.18}
                  minScale={isDesktop ? 0.7 : 0.18}
                  maxScale={isDesktop ? 2 : 1.2}
                  centerOnInit
                >
                  <TransformComponent wrapperStyle={{ width: "100%", height: "100%", minHeight: "200px" }}>
                    <div className="knockout-container">
                      {(knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.LARGE ||
                        knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.MEDIUM ||
                        knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.SMALL ||
                        knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.MINI) && (
                        <>
                          <ThirdTeams />
                          <FinalTeams />
                        </>
                      )}
                      {(knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.LARGE ||
                        knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.MEDIUM ||
                        knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.SMALL) && (
                        <>
                          <SemiFinalsLines />
                          <SemiFinalsTeams />
                        </>
                      )}
                      {(knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.LARGE ||
                        knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.MEDIUM) && (
                        <>
                          <QuarterFinalsLeftLines />
                          <QuarterFinalsTeams />
                        </>
                      )}
                      {knockoutTournamentSize === KNOCK_OUT_TOURNAMENT_SIZE.LARGE && (
                        <>
                          <RoundOf16Lines />
                          <RoundOf16Teams />
                        </>
                      )}
                    </div>
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </Box>
          )}
        </Stack>
      </Container>
    </>
  );
};

export default KnockOut;
