import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface Props {
  items: MenuItemType[];
}

interface MenuItemType {
  onClick: () => void;
  label: string;
}

export default function KebabMenu(props: Props) {
  const { items } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemClickedHandler = (callback: () => void) => {
    handleClose();
    callback();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ margin: "10px 5px", padding: 0, position: "absolute", top: 0, right: 0, color: "grey", minWidth: "0" }}
      >
        <MoreVertIcon style={{ fontSize: "20px" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {items.map((item) => (
          <MenuItem key={item.label} onClick={() => menuItemClickedHandler(item.onClick)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
