import { Match, STATUS, TeamType } from "../types/types";
import ballLogo from "../assets/icons/ball-logo.png";
import { useAppSelector } from "../hooks";
import { Card, CardActionArea, CardContent } from "@mui/material";

interface Props {
  match?: Match;
  onClickCallback: (match: Match) => void;
}

function KnockOutMatchComponent(props: Props) {
  const { match, onClickCallback } = props;
  const admin = useAppSelector((state) => state.app.admin);

  const getLogo = (team: TeamType | undefined) => {
    if (!team) return <></>;

    if (team.club?.logo) {
      return <img src={team.club?.logo} />;
    } else {
      return <img src={ballLogo} />;
    }
  };

  const getName = (team?: TeamType, label?: string) => {
    if (team?.teamName) return team.teamName;

    if (label) {
      return label;
    } else {
      return "Pending";
    }
  };

  const getStatusColor = () => {
    switch (match?.status) {
      case STATUS.PLAYING:
        return "rgba(255, 165, 0, 0.3)";
      case STATUS.FINISHED:
        return "rgba(0, 255, 0, 0.3)";
      default:
        return "rgba(0, 0, 0, 0)";
    }
  };

  return (
    <div
      className="knockout-match"
      style={{
        filter: `drop-shadow(0 0 0.75rem ${getStatusColor()})`,
      }}
    >
      <Card sx={{ backgroundColor: "transparent" }} onClick={() => admin && match && onClickCallback(match)}>
        <CardActionArea disabled={!admin}>
          <CardContent sx={{ padding: 0 }}>
            <div className="team-container">
              <div className="team-name">
                {getLogo(match?.homeTeam)}
                {getName(match?.homeTeam, match?.homeTeamLabel)}
              </div>
              <div className="point-container">{match?.status === STATUS.PENDING ? "-" : match?.homeTeamPoints}</div>
            </div>
            <div className="team-container">
              <div className="team-name">
                {getLogo(match?.awayTeam)}
                {getName(match?.awayTeam, match?.awayTeamLabel)}
              </div>
              <div className="point-container">{match?.status === STATUS.PENDING ? "-" : match?.awayTeamPoints}</div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

export default KnockOutMatchComponent;
