import { useEffect, useRef } from "react";
import Alert from "./Alert";
import { Severity } from "../../types/types";

export interface ToastModel {
  severity: Severity;
  duration?: number;
  text: string;
}

interface Props {
  onClose: () => void;
  toast: ToastModel;
}

const Toast = (props: Props) => {
  const { onClose } = props;
  const { severity, duration } = props.toast;
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (duration) {
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => onClose && onClose(), duration);
    }
  }, [duration, onClose]);

  return <Alert severity={severity} text={props.toast.text} onClose={onClose} />;
};

export default Toast;
