import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Card, Container, Stack, SvgIcon, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Order } from "../../../components/table";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import NotificationService from "../../../components/notification/NotificationService";
import { dbDeleteGroup } from "../../../api/api";
import { removeGroup, setEditingGroup } from "../../../features/groups/GroupsSlice";
import useConfirm from "../../../hooks/useConfirm";
import { GroupType } from "../../../types/types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import PuljeSelectorTabs from "../../../components/pulje-selector-tabs";
import { TableCellIconStyles } from "../../../styles/theme";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

export default function Groups() {
  const dispatch = useAppDispatch();
  const teams = useAppSelector((state) => state.teams.teams);
  const selectedPulje = useAppSelector((state) => state.puljer.selectedPulje);
  const _groups = useAppSelector((state) => state.groups.groups);
  const groups = _groups.filter((group) => (group.pulje as any).id === selectedPulje?.id);
  const navigate = useNavigate();

  const [Dialog, confirmDelete] = useConfirm("Er du sikker?", "Er du sikker på at du vil slette denne gruppe?");

  useEffect(() => {
    dispatch(setEditingGroup(undefined));
  }, []);

  const deleteGroupHandler = async (groupId: string, groupName: string) => {
    // Check if any teams are in this group first!
    const teamsInGroup = teams.filter((team) => team.group?.id === groupId);
    if (teamsInGroup.length > 0) {
      NotificationService.showToast({
        severity: "error",
        text: `${teamsInGroup.length} hold er allerede i denne gruppe.`,
        duration: 3000,
      });
      return;
    }

    const confirmed = await confirmDelete();
    if (confirmed) {
      dbDeleteGroup(groupId)
        .then(() => {
          dispatch(removeGroup(groupId));
          NotificationService.showToast({
            severity: "success",
            text: `Gruppen blev slettet`,
            duration: 3000,
          });
        })
        .catch((e) => {
          console.error(e);
          NotificationService.showToast({
            severity: "error",
            text: `Gruppen kunne ikke slettes.`,
            duration: 3000,
          });
        });
    }
  };

  const editGroupHandler = (group: GroupType) => {
    dispatch(setEditingGroup(group));
    navigate(ROUTES.ADMIN_CREATE_GROUP);
  };

  return (
    <>
      {Dialog()}
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="h4">Grupper</Typography>
            <div>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
                onClick={() => navigate(ROUTES.ADMIN_CREATE_GROUP)}
              >
                Opret
              </Button>
            </div>
          </Stack>
          <PuljeSelectorTabs />
          <Card>
            <Box sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Gruppe</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groups.map((group, i) => {
                    return (
                      <TableRow hover key={group.id}>
                        <TableCell>
                          <Stack alignItems="center" direction="row" spacing={2}>
                            <Typography variant="subtitle2">{group.name}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "40px" }}
                        >
                          <ModeEditOutlineOutlinedIcon sx={TableCellIconStyles} onClick={() => editGroupHandler(group)} />
                          <DeleteOutlineOutlinedIcon
                            sx={TableCellIconStyles}
                            onClick={() => deleteGroupHandler(group.id, group.name)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Stack>
      </Container>
    </>
  );
}
