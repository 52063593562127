import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import TextField from "@mui/material/TextField";
import { KNOCK_OUT_TOURNAMENT_SIZE, Pulje, TeamType } from "../../../types/types";
import { dbCreatePulje, dbUpdatePulje } from "../../../api/api";
import NotificationService from "../../../components/notification/NotificationService";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { createPulje, setPuljer } from "../../../features/puljer/PuljeSlice";
import { forIn } from "lodash";
import { knockoutSizeConverter } from "../../../utils";

interface Props {
  selectedTeam?: TeamType;
}

const CreatePulje = (props: Props) => {
  const dispatch = useAppDispatch();
  const editingPulje = useAppSelector((state) => state.puljer.editingPulje);
  const [puljeName, setPuljeName] = useState(editingPulje?.puljeName ?? "");
  const [puljeSize, setPuljeSize] = useState(editingPulje?.puljeSize ?? KNOCK_OUT_TOURNAMENT_SIZE.NONE);
  const [groupMatchTime, setGroupMatchTime] = useState(editingPulje?.groupMatchTime ? editingPulje?.groupMatchTime : 10);
  const [knockoutMatchTime, setKnockoutMatchTime] = useState(
    editingPulje?.knockoutMatchTime ? editingPulje?.knockoutMatchTime : 10
  );
  const puljer = useAppSelector((state) => state.puljer.puljer);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));
  const tournament = useAppSelector((state) => state.tournament.tournament);

  const updatePuljeHandler = () => {
    if (!editingPulje) return;

    dbUpdatePulje(editingPulje.id, {
      id: editingPulje.id,
      puljeName,
      puljeSize: puljeSize as KNOCK_OUT_TOURNAMENT_SIZE,
      groupMatchTime: groupMatchTime,
      knockoutMatchTime: knockoutMatchTime,
      tournament: tournament.id,
    })
      .then(() => {
        const updatedPuljer = puljer.map((_pulje) => {
          if (_pulje.id === editingPulje.id) {
            return {
              id: _pulje.id,
              puljeName,
              groupMatchTime,
              knockoutMatchTime,
              puljeSize,
              tournament: tournament,
            } as Pulje;
          } else {
            return _pulje;
          }
        });

        dispatch(setPuljer(updatedPuljer));
        navigate(ROUTES.ADMIN_PULJER);
        NotificationService.showToast({
          severity: "success",
          text: "Puljen blev opdateret.",
          duration: 3000,
        });
      })
      .catch((error) => {
        NotificationService.showToast({
          severity: "error",
          text: error,
          duration: 3000,
        });
        console.error(error);
      });
  };

  const createPuljeHandler = () => {
    const puljeExists = puljer.some((pulje) => pulje.puljeName === puljeName);
    if (puljeExists) {
      NotificationService.showToast({
        text: "Pulje med dette navn eksisterer allerede.",
        severity: "error",
        duration: 3000,
      });
      return;
    }

    const newPulje = {
      puljeName,
      groupMatchTime,
      knockoutMatchTime,
      puljeSize: puljeSize as KNOCK_OUT_TOURNAMENT_SIZE,
      tournament: tournament.id,
    };

    dbCreatePulje(newPulje)
      .then((id) => {
        dispatch(createPulje({ id, tournament, groupMatchTime, puljeName, puljeSize, knockoutMatchTime }));
        NotificationService.showToast({
          severity: "success",
          text: `Pulje '${puljeName}' blev oprettet!`,
          duration: 3000,
        });
        navigate(ROUTES.ADMIN_PULJER);
      })
      .catch((e) => {
        console.error(e);
        NotificationService.showToast({
          severity: "error",
          text: `Puljen kunne ikke oprettes.`,
          duration: 3000,
        });
      });
  };

  useEffect(() => {
    if (puljeName.length > 12) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [puljeName, open]);

  const options = () => {
    const options: { label: string; value: string | number }[] = [];
    forIn(KNOCK_OUT_TOURNAMENT_SIZE, (value, key) => {
      isNaN(Number(key)) && options.push({ label: key, value });
    });
    return options;
  };

  return (
    <>
      <Container maxWidth={lgUp ? "sm" : "lg"}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="flex" spacing={4}>
            <Typography variant="h4">{editingPulje ? "Opdater pulje" : "Opret pulje"}</Typography>
          </Stack>
          <Card>
            <form autoComplete="off" noValidate onSubmit={() => {}}>
              <Card>
                <CardHeader subheader="Udfyld følgende oplysninger:" title={editingPulje ? "Opdater pulje" : "Ny pulje"} />
                <CardContent sx={{ pt: 0 }}>
                  <Box sx={{ m: -1.5 }}>
                    <Grid container>
                      <Grid xs={12} padding={1} item>
                        <TextField
                          fullWidth
                          label="Navn"
                          name="pulje"
                          onChange={(e) => setPuljeName(e.target.value)}
                          required
                          value={puljeName}
                        />
                      </Grid>
                      <Grid xs={12} padding={1} item>
                        <TextField
                          fullWidth
                          label="Antal minutter i gruppespil"
                          name="pulje"
                          onChange={(e) => setGroupMatchTime(Number(e.target.value))}
                          required
                          value={groupMatchTime}
                          type="number"
                        />
                      </Grid>
                      <Grid xs={12} padding={1} item>
                        <TextField
                          fullWidth
                          label="Antal minutter i Knock out"
                          name="pulje"
                          onChange={(e) => setKnockoutMatchTime(Number(e.target.value))}
                          required
                          value={knockoutMatchTime}
                          type="number"
                        />
                      </Grid>
                      <Grid xs={12} padding={1} item>
                        <TextField
                          fullWidth
                          label="Knock-out hold"
                          name="pulje-size"
                          onChange={(e) => setPuljeSize(e.target.value as KNOCK_OUT_TOURNAMENT_SIZE)}
                          select
                          SelectProps={{ native: true }}
                          value={puljeSize}
                        >
                          {Object.keys(KNOCK_OUT_TOURNAMENT_SIZE).map((option) => (
                            <option key={option} value={option}>
                              {knockoutSizeConverter(option as KNOCK_OUT_TOURNAMENT_SIZE)}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button variant="outlined" onClick={() => navigate(ROUTES.ADMIN_PULJER)}>
                    Fortryd
                  </Button>
                  <Button
                    disabled={hasError || !puljeName}
                    variant="contained"
                    onClick={editingPulje ? updatePuljeHandler : createPuljeHandler}
                  >
                    {editingPulje ? "Opdater" : "Opret"}
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Card>
        </Stack>
      </Container>
    </>
  );
};

export default CreatePulje;
