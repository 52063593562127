import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setSelectedPulje } from "../features/puljer/PuljeSlice";
import { useEffect, useState } from "react";
import { Pulje } from "../types/types";

export default function PuljeSelectorTabs() {
  const selectedPulje = useAppSelector((state) => state.puljer.selectedPulje);
  const [value, setValue] = useState(selectedPulje);
  const puljer = useAppSelector((state) => state.puljer.puljer);
  const dispatch = useAppDispatch();

  const handleChange = (pulje: Pulje) => {
    setValue(pulje);
    dispatch(setSelectedPulje(pulje));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={(_, value) => handleChange(value)}>
          {puljer.map((pulje) => (
            <Tab key={pulje.id} label={pulje.puljeName} value={pulje} />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
}
