import { useState, useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import TextField from "@mui/material/TextField";
import { Box, Card, CardActions, CardContent, CardHeader, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { dbCreateGroupMatch } from "../../../api/api";
import { createGroupMatch } from "../../../features/matches/matchesSlice";
import { GroupType, LocationType, Match, MATCH_TYPE, Pulje, STATUS, TeamType } from "../../../types/types";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { TableCellIconStyles } from "../../../styles/theme";
import NotificationService from "../../../components/notification/NotificationService";
import { getGuid } from "../../../utils";

interface Props {
  editingMatch?: Match;
}

const CreateMatch = (props: Props) => {
  const { editingMatch } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const puljer = useAppSelector((state) => state.puljer.puljer);
  const _locations = useAppSelector((state) => state.locations.locations);
  const _teams = useAppSelector((state) => state.teams.teams);
  const _groups = useAppSelector((state) => state.groups.groups);
  const _matches = useAppSelector((state) => state.matches.groupMatches);
  const _tournament = useAppSelector((state) => state.tournament.tournament);
  const _settings = useAppSelector((state) => state.settings.settings);

  const [homeTeam, setHomeTeam] = useState<string>("");
  const [awayTeam, setAwayTeam] = useState<string>("");
  const [duration, setDuration] = useState(0);
  const [group, setGroup] = useState<string>("");
  const [pulje, setPulje] = useState<string>("");
  const [locationId, setLocation] = useState<string>("");

  const [homeTeamPoints, setHomeTeamPoints] = useState(0);
  const [awayTeamPoints, setAwayTeamPoints] = useState(0);

  const _handleClose = () => {
    navigate(ROUTES.ADMIN_MATCHES);
  };

  const createNewMatch = () => {
    const _homeTeam = _teams.find((team) => team.id === homeTeam);
    const _awayTeam = _teams.find((team) => team.id === awayTeam);
    const _group = _groups.find((_group) => _group.id === group);
    const _pulje = puljer.find((_pulje) => _pulje.id === pulje);
    const _location = _locations.find((location) => location.id === locationId);

    const startTime = findNextAvailableTimeSlot(locationId);
    const endTime = startTime + duration * 60;

    if (!_homeTeam || !_awayTeam || !_group || !_location || !_pulje) return;

    dbCreateGroupMatch({
      id: getGuid(),
      awayTeam,
      awayTeamPoints: 0,
      startTime,
      endTime,
      group: group,
      homeTeam,
      homeTeamPoints: 0,
      location: locationId,
      status: STATUS.PENDING,
      matchType: MATCH_TYPE.GROUP_MATCH,
      pulje,
      tournament: _tournament.id,
      duration,
    })
      .then((id: string) => {
        dispatch(
          createGroupMatch({
            id,
            homeTeam: _homeTeam,
            awayTeam: _awayTeam,
            awayTeamPoints: 0,
            startTime: startTime,
            endTime: endTime,
            group: _group,
            homeTeamPoints: 0,
            location: _location,
            status: STATUS.PENDING,
            matchType: MATCH_TYPE.GROUP_MATCH,
            pulje: _pulje,
            tournament: _tournament,
            duration: duration,
          })
        );
        _handleClose();
      })
      .catch((e) => {
        console.error(e);
        NotificationService.showToast({
          severity: "error",
          text: "Kunne ikke oprette kamp",
          duration: 5000,
        });
        alert("Error, cound not create match.");
      });
  };

  const updateMatch = () => {
    /*
    if (!editingMatch || !editingMatch?.group?.id) return;
    dbUpdateGroupMatch2(editingMatch.id, {
      id: editingMatch.id,
      awayTeam,
      homeTeam,
      endTime: moment(endTime).unix(),
      startTime: moment(startTime).unix(),
      group: groupId,
      location: locationId,
      pulje: pulje,
      tournament: tournament.id,
      duration,
    })
      .then(() => {
        _handleClose();
      })
      .catch((e) => {
        console.error(e);
        alert("Error, cound not update match.");
      });
      */
  };

  //Initial filtering based on the editing match' pulje
  useEffect(() => {
    const puljeId = puljer[0].id;
    setPulje(puljeId);
    setDuration(puljer[0].groupMatchTime);
    setLocation(_locations[0].id);
  }, []);

  const filteredGroups = useMemo(() => {
    const filteredGroups = _groups.filter((group) => group.pulje?.id === pulje);
    setGroup(filteredGroups[0]?.id);
    return filteredGroups;
  }, [pulje]);

  const filteredTeams = useMemo(() => {
    const filteredTeams = _teams.filter((_team) => _team.group?.id === group);
    setHomeTeam(filteredTeams[0]?.id);
    setAwayTeam(filteredTeams[0]?.id);
    return filteredTeams;
  }, [group]);

  const onSwapHandler = () => {
    const prevHomeTeam = homeTeam;
    const prevHomeTeamPoints = homeTeamPoints;

    setHomeTeam(awayTeam);
    setAwayTeam(prevHomeTeam);

    setHomeTeamPoints(awayTeamPoints);
    setAwayTeamPoints(prevHomeTeamPoints);
  };

  const onPuljeChangedHandler = (puljeId: string) => {
    setPulje(puljeId);
    setDuration(puljer.find((pulje) => pulje.id === puljeId)?.groupMatchTime ?? 0);
  };

  const onDurationChangedHandler = (newDuration: number) => {
    setDuration(newDuration);
  };

  const findNextAvailableTimeSlot = (locationId: string) => {
    const matchesOnLocation = _matches
      .filter((match) => match.location.id === locationId)
      .sort((a, b) => a.startTime - b.startTime);
    if (matchesOnLocation.length === 0) return _tournament.date;
    const lastMatchEndTime = matchesOnLocation[matchesOnLocation.length - 1].endTime;
    const gapTime = (_settings?.gapTime ?? 0) * 60;
    return lastMatchEndTime + gapTime;
  };

  return (
    <Container maxWidth={"sm"}>
      <Stack spacing={3}>
        <Typography variant="h4">{editingMatch ? "Opdater kamp" : "Opret kamp"}</Typography>
        <Card>
          <form autoComplete="off" noValidate onSubmit={() => {}}>
            <Card>
              <CardHeader subheader="Udfyld følgende oplysninger:" title={editingMatch ? "Opdater kamp" : "Ny kamp"} />
              <CardContent sx={{ pt: 0 }}>
                <Box sx={{ m: -1.5 }}>
                  <Grid container justifyContent="space-between">
                    <Grid xs={12} md={6} padding={1} item>
                      <TextField
                        fullWidth
                        label="Pulje"
                        name="group"
                        onChange={(e) => onPuljeChangedHandler(e.target.value)}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={pulje}
                        disabled={!!editingMatch}
                      >
                        {puljer.map((pulje: Pulje) => (
                          <option key={pulje.id} value={pulje.id}>
                            {pulje.puljeName}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid xs={12} md={6} padding={1} item>
                      <TextField
                        fullWidth
                        label="Gruppe"
                        name="group"
                        onChange={(e) => setGroup(e.target.value)}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={group}
                        disabled={!!editingMatch}
                      >
                        {filteredGroups.map((group: GroupType) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid xs={4} md={5} padding={1} item>
                        <TextField
                          fullWidth
                          label="Hjemmehold"
                          name="group"
                          onChange={(e) => setHomeTeam(e.target.value)}
                          required
                          select
                          SelectProps={{ native: true }}
                          value={homeTeam}
                        >
                          {filteredTeams.map((team: TeamType) => (
                            <option key={team.id} value={team.id}>
                              {team.teamName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <SwapHorizIcon sx={TableCellIconStyles} onClick={onSwapHandler} />
                      <Grid xs={4} md={5} padding={1} item>
                        <TextField
                          fullWidth
                          label="Udehold"
                          name="group"
                          onChange={(e) => setAwayTeam(e.target.value)}
                          required
                          select
                          SelectProps={{ native: true }}
                          value={awayTeam}
                        >
                          {filteredTeams.map((team: TeamType) => (
                            <option key={team.id} value={team.id}>
                              {team.teamName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center">
                      {/*                       <Grid xs={12} md={6} padding={1} item>
                        <DatePicker
                          value={startTime}
                          label="Start tidspunkt"
                          onChange={(value) => setStartTime(value)}
                          renderInput={(params) => <TextField {...params} />}
                          inputFormat="HH:mm"
                          disableOpenPicker
                          disabled
                        />
                      </Grid>
                      <Grid xs={12} md={6} padding={1} item>
                        <DatePicker
                          value={endTime}
                          label="Slut tidspunkt"
                          onChange={(value) => setEndTime(value)}
                          renderInput={(params) => <TextField {...params} />}
                          inputFormat="HH:mm"
                          disableOpenPicker
                          disabled
                        />
                      </Grid> */}
                      <Grid xs={12} md={6} padding={1} item>
                        <TextField
                          type="number"
                          label="Antal minutter"
                          value={duration}
                          onChange={(e) => onDurationChangedHandler(Number(e.target.value))}
                        />
                      </Grid>
                      <Grid xs={12} md={6} padding={1} item>
                        <TextField
                          fullWidth
                          label="Bane"
                          name="location"
                          onChange={(e) => setLocation(e.target.value)}
                          required
                          select
                          SelectProps={{ native: true }}
                          value={locationId}
                        >
                          {_locations.map((location: LocationType) => (
                            <option key={location.id} value={location.id}>
                              {location.location}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
              <Divider />
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button variant="outlined" onClick={() => navigate(ROUTES.ADMIN_MATCHES)}>
                  Fortryd
                </Button>
                <Button variant="contained" onClick={editingMatch ? updateMatch : createNewMatch}>
                  {editingMatch ? "Opdater" : "Opret"}
                </Button>
              </CardActions>
            </Card>
          </form>
        </Card>
      </Stack>
    </Container>
  );
};

export default CreateMatch;
