import { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Button from "@mui/material/Button";
import { dbDeleteLocation } from "../../../api/api";
import { removeLocation, setEditingLocation } from "../../../features/locations/locationsSlice";
import NotificationService from "../../../components/notification/NotificationService";
import useConfirm from "../../../hooks/useConfirm";
import { Card, Container, Stack, SvgIcon, Typography } from "@mui/material";
import { LocationType } from "../../../types/types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { TableCellIconStyles } from "../../../styles/theme";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

export default function Locations() {
  const dispatch = useAppDispatch();
  const locations = useAppSelector((state) => state.locations.locations);
  const matches = useAppSelector((state) => state.matches.groupMatches);

  const [Dialog, confirmDelete] = useConfirm("Er du sikker?", "Er du sikker på at du vil slette denne bane?");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setEditingLocation(undefined));
  }, []);

  const deleteLocationHandler = async (locationId: string, locationName: string) => {
    // Check if any matches are using the location first!

    const matchesUsingLocation = matches.filter((match) => match.location.id === locationId);

    if (matchesUsingLocation.length > 0) {
      NotificationService.showToast({
        severity: "error",
        text: `${matchesUsingLocation.length} kampe bruger denne lokation.`,
        duration: 5000,
      });
      return;
    }

    const confirmed = await confirmDelete();
    if (confirmed) {
      dbDeleteLocation(locationId)
        .then(() => {
          dispatch(removeLocation(locationId));
          NotificationService.showToast({
            severity: "success",
            text: `Lokationen blev slettet`,
            duration: 5000,
          });
        })
        .catch((e) => {
          console.error(e);
          NotificationService.showToast({
            severity: "error",
            text: `Lokationen kunne ikke slettes.`,
            duration: 5000,
          });
        });
    }
  };

  const editLocationHandler = (location: LocationType) => {
    dispatch(setEditingLocation(location));
    navigate(ROUTES.ADMIN_CREATE_LOCATION);
  };

  return (
    <>
      {Dialog()}
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="h4">Baner</Typography>
            <div>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
                onClick={() => navigate(ROUTES.ADMIN_CREATE_LOCATION)}
              >
                Opret
              </Button>
            </div>
          </Stack>
          <Card sx={{ width: "100%" }}>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {locations.map((location: LocationType) => {
                    return (
                      <TableRow hover key={location.id}>
                        <TableCell>
                          <Typography variant="subtitle2">{location.location}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "40px" }}
                        >
                          <ModeEditOutlineOutlinedIcon
                            sx={TableCellIconStyles}
                            onClick={() => editLocationHandler(location)}
                          />
                          <DeleteOutlineOutlinedIcon
                            sx={TableCellIconStyles}
                            onClick={() => deleteLocationHandler(location.id, location.location)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Stack>
      </Container>
    </>
  );
}
