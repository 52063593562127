import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { LocationType } from "../types/types";
import { dialogActionsStyles, dialogContentStyles, dialogStyles } from "../styles/theme";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { dbUpdateLocation } from "../api/api";
import { useAppSelector } from "../hooks";
import { iconStyles } from "./match-data";

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedLocation: LocationType;
}

const UpdateLocationDialog = (props: Props) => {
  const { handleClose, open, selectedLocation } = props;
  const tournament = useAppSelector((state) => state.tournament.tournament);

  const updateLocation = (newValue: number) => {
    dbUpdateLocation({
      id: selectedLocation.id,
      tournament: tournament.id,
      location: selectedLocation.location,
      delay: newValue,
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} sx={dialogStyles}>
        <DialogContent sx={dialogContentStyles}>
          <div style={{ color: "white" }}>Antal minutter forsinket</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
          >
            <KeyboardArrowUpIcon onClick={() => updateLocation(selectedLocation?.delay + 1)} sx={iconStyles} />
            {selectedLocation?.delay}
            <KeyboardArrowDownIcon onClick={() => updateLocation(selectedLocation?.delay - 1)} sx={iconStyles} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UpdateLocationDialog;
